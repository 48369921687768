import React, { useRef, useEffect } from 'react';
import { Box, Button } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { selectUserSelectedLocal } from 'store/slices/common';

import 'swiper/css';
import './style.scss';

export const SubSlider = ({ handleSlideChange, item, index }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const onSlide = swiper => {
    const currentActiveIndex = swiper.activeIndex;
    handleSlideChange(index, currentActiveIndex);
  };

  const center = useSelector(selectUserSelectedLocal);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0); // 첫 번째 슬라이드로 강제 이동
    }
  }, []);

  return (
    <>
      <Box className="sub-slider">
        <Swiper
          ref={swiperRef}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true
          }}
          navigation={{
            prevEl: prevRef.current, //스와이퍼 외부에 컨트롤러 설정하기
            nextEl: nextRef.current
          }}
          onBeforeInit={swiper => {
            // 초기화 전에 네비게이션 버튼을 swiper에 할당합니다.
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          onSlideChange={onSlide}
          onSwiper={swiper => {
            swiperRef.current = swiper;
          }}
          // activeIndex={activeIndex}
          key={JSON.stringify(item)}
        >
          {item.map((file, fileIndex) => (
            <SwiperSlide className="slider-item index" key={fileIndex}>
              <img alt={file.fileDisplayName || `${center}센터 이미지`} src={file.fileUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button className="slider-prev" ref={prevRef}>
          Prev
        </Button>
        <Button className="slider-next" ref={nextRef}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default SubSlider;
