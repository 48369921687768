export const localList = [
  { name: '전체', code: 'ALL', en: 'all' },
  { name: '강원', code: 'GW', en: 'gangwon' },
  { name: '경기', code: 'GG', en: 'gyeonggi' },
  { name: '경남', code: 'GN', en: 'gyeongnam' },
  { name: '경북', code: 'GB', en: 'gyeongbuk' },
  { name: '광주', code: 'GJ', en: 'gwangju' },
  { name: '대구', code: 'DG', en: 'daegu' },
  { name: '울산', code: 'US', en: 'ulsan' },
  { name: '인천', code: 'IC', en: 'incheon' },
  { name: '전남', code: 'JN', en: 'jeonnam' },
  { name: '전북', code: 'JB', en: 'jeonbuk' },
  { name: '제주', code: 'JJ', en: 'jeju' },
  { name: '충남', code: 'CN', en: 'chungnam' },
  { name: '충북', code: 'CB', en: 'chungbuk' }
];

export const sitemap = [
  {
    depth1: { name: '소개', url: '/intro' },
    depth2: [
      { name: '사업소개', url: '/intro' },
      { name: '사업 추진체계', url: '/promotionSystem' },
      { name: '지역센터소개', url: '/localCenter' },
      { name: '사업 CI', url: '/ci' },
      { name: '조직도', url: '/localOrganization', hide: true },
      { name: '찾아오시는 길', url: '/localMap', hide: true }
    ]
  },
  {
    depth1: { name: '교육안내', url: '/education' },
    depth2: [{ name: '교육정보', url: '/education' }]
  },
  {
    depth1: { name: '행사안내', url: '/event' },
    depth2: [{ name: '행사정보', url: '/event' }]
  },
  {
    depth1: { name: '공지사항', url: '/notice' },
    depth2: [{ name: '공지사항', url: '/notice' }]
  },
  {
    depth1: { name: '홍보자료', url: '/promotion' },
    depth2: [{ name: '홍보자료', url: '/promotion' }]
  }
];

export const mapLongdescList = {
  GW: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 강원특별자치도와 강원과학산업진흥원을 중심으로 춘천시, 원주시, 강릉시, SWA 미래채움이 참여하는 강원 지역 과학기술 관련 사업 협력 체계를 보여주는 이미지입니다.',
  GG: '과학기술정보통신부가 총괄부처로, 정보통신산업진흥원이 전담기관, 경기도경제과학진흥원이 주관기관으로 참여하는 사업 구조를 나타냅니다. 경기도와 경기도교육청이 협력하며, SW미래채움 경기센터가 사업 운영을 담당합니다.',
  GN: '경남테크노파크 정보산업진흥본부는 새미래행복교육 사회적협동조합, 경상남도교육청, 경상남도 다문화가족지원센터를 산하 기관으로 두고 있습니다.',
  GB: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 경상북도와 경북SW진흥본부를 중심으로 포항시, SWA 미래채움, 경북SW교육개선위원회, 경북SW교육강사협의회, 학교, 학생이 참여하는 경북 지역 소프트웨어 교육 관련 사업 협력 체계를 보여주는 이미지입니다.',
  GJ: '과학기술정보통신부의 지원을 받아 정보통신산업진흥원(NIPA)이 주관하고, 광주광역시와 광주광역시교육청이 협력하여 미래채움 교육위원회, Jicon, U-MAKERS와 함께 광주 지역의 SW 인재 양성을 위한 다양한 교육 프로그램을 운영하고 있습니다.',
  DG: '과학기술정보통신부의 지원을 받아 정보통신산업진흥원(NIPA)이 주관하고, 대구광역시와 대구광역시교육청이 협력하여 대구디지털혁신진흥원, 계명대학교 산학협력단, SWA 미래채움과 함께 대구 지역의 SW 인재 양성을 위한 다양한 교육 프로그램을 운영하고 있습니다.',
  US: '과학기술정보통신부의 지원을 받아 정보통신산업진흥원(NIPA)이 주관하고, 울산광역시와 울산광역시 북구가 협력하여 울산정보산업진흥원, 울산광역시교육청, SWA 미래채움과 함께 울산 지역의 SW 인재 양성을 위한 다양한 교육 프로그램을 운영하고 있습니다.',
  IC: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 인천광역시교육청과 협력하여 ITP(인천테크노파크)와 강화군이 참여하는 인천 지역 고등학생 대상 SW 교육 활성화를 위한 사업 협력 체계를 보여주는 이미지입니다.',
  JN: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 전라남도교육청과 전라남도를 중심으로 각 시군 교육지원청, Jeia(전남정보문화산업진흥원), SW교육 관련 위원회 등이 참여하여 전남 지역 학생들의 SW 역량 강화를 위한 다양한 교육 프로그램 및 인프라 구축 사업을 나타냅니다.',
  JB: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 전북특별자치도와 전북교육청을 중심으로 JBTp(전북테크노파크), KETI(한국전자기술연구원), 플레이코딩 등이 참여하여 전북 지역 학생들의 SW 역량 강화를 위한 다양한 교육 프로그램 및 인프라 구축 사업을 나타냅니다.',
  JJ: '과학기술정보통신부의 지원을 받아 정보통신산업진흥원(NIPA)이 주관하고, 제주특별자치도가 협력하여 TMD교육그룹, SWA 미래채움, 제주 소프트웨어 산업발전협의회 등이 참여하는 제주 지역의 SW교육을 활성화하고, 학생들의 디지털 역량을 강화하기 위한 다양한 사업을 추진하는 협력 네트워크를 보여줍니다.',
  CN: '과학기술정보통신부가 주도하고 정보통신산업진흥원(NIPA)이 전담하며, 충청남도와 천안시를 중심으로 남서울대학교가 참여하여 충남 지역 학생들의 SW 역량 강화를 위한 다양한 교육 프로그램 및 인프라 구축 사업을 나타냅니다.',
  CB: '과학기술정보통신부의 지원을 받아 정보통신산업진흥원(NIPA)이 주관하고, 충청북도와 충청북도 내 각 시군이 협력하여 충청북도교육청, 충북과학기술혁신원, SWA 미래채움 등이 참여하는 충북 지역의 SW교육을 활성화하고, 학생들의 디지털 역량을 강화하기 위한 다양한 사업을 추진하는 협력 네트워크를 보여줍니다.'
};
