import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE;

export const defaultHeaders = {
  'Content-Type': 'application/json'
};

export const createCustomInstance = (customURL, config = defaultHeaders) => {
  return axios.create({
    baseURL: customURL,
    headers: config,
    timeout: 5000 // 시간이 오래 걸리는 요청에 대한 timeout 값 재정의, 5초
  });
};

// API instance
export const instance = createCustomInstance(baseURL);

/**
 * API 호출 전 공통 로직
 */
instance.interceptors.request.use(
  config => {
    console.log('config : ', config);
    console.debug(`request => ${config.url}`, config);

    // API 호출 전 엑세스 토큰 로직 추가
    // const accessToken = getAccessToken();
    // const token = 'Bearer ${accessToken}';
    // if (token) {
    //     config.headers.authorization = token;
    // }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

/**
 * API 호출 후 공통 로직
 */
// instance.interceptors.response.use(
//     response => {
//         console.debug(`response => ${response.config.url}`, response);
//         return response;
//     },
//     err => {
//         const { response } = err;
//         console.debug(`err => ${err}`);
//         const { status } = response ?? 500;
//         if (status >= 500) {
//             response.data = {
//                 message: '서버 에러 잠시 후 다시 시작해주세요.',
//                 token: null
//             };
//         }
//         return Promise.reject(response);
//     }
// );
