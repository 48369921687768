import React from 'react';
import { Box, Flex, Table, Heading, Text } from '@radix-ui/themes';
import NoData from 'components/common/noData';
import { selectUserSelectedLocal, selectUserSelectedLocalCode } from 'store/slices/common';
import { useSelector } from 'react-redux';
import { mapLongdescList } from 'app/constants';

const OrganizationChart = ({ localData }) => {
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const userSelectedLocal = useSelector(selectUserSelectedLocal);

  return (
    <>
      <Box mt="var(--space-12)" mb="var(--space-15)" px="var(--space-10)">
        <Flex justify="center" className="section-inner">
          <div className="image-wrap organization">
            <img
              src={require(`assets/images/pages/organization/map-${userSelectedLocalCode}.png`)}
              alt={`${userSelectedLocal}센터 조직도`}
              longdesc={`${mapLongdescList[userSelectedLocalCode]}`}
            />
          </div>
        </Flex>
      </Box>

      {localData?.details ? (
        localData.details?.map((item, index) => (
          <Box pt="var(--space-15)" className="line table-wrap" key={index}>
            <Box mb="var(--space-6)">
              <Heading as="h5" className="title4">
                {item.orgName}
              </Heading>
              <Text as="p" className="sub-title">
                {item.orgInfo}
              </Text>
            </Box>
            <Table.Root layout="auto" className="data-table">
              <caption>센터 안내</caption>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell justify="center" py="var(--space-5)" className="sub-title" width="100px">
                    직책
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" py="var(--space-5)" className="sub-title" width="120px">
                    이름
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" py="var(--space-5)" className="sub-title" width="200px">
                    전화번호
                  </Table.ColumnHeaderCell>
                  {/*<Table.ColumnHeaderCell*/}
                  {/*  justify="center"*/}
                  {/*  py="var(--space-5)"*/}
                  {/*  className="sub-title"*/}
                  {/*  width="200px"*/}
                  {/*>*/}
                  {/*  이메일*/}
                  {/*</Table.ColumnHeaderCell>*/}
                  <Table.ColumnHeaderCell justify="center" py="var(--space-5)" className="sub-title">
                    업무내용
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {item.managers?.map((manager, index) => (
                  <Table.Row key={index}>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.rank || '-'}
                    </Table.Cell>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.name || '-'}
                    </Table.Cell>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.phone || '-'}
                    </Table.Cell>
                    {/*<Table.Cell justify="center" py="22px" className="body1">*/}
                    {/*  {manager.email}*/}
                    {/*</Table.Cell>*/}
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.duty}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        ))
      ) : (
        <NoData />
      )}
    </>
  );
};

export default OrganizationChart;
