import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex, Strong, Text } from '@radix-ui/themes';
import { useDispatch } from 'react-redux';

import LocalSort from 'components/common/localSort';
import BoardSearchBox from 'components/board/boardSearchBox';
import { ListType } from 'components/board/boardTextItems';
import Pagination from 'components/common/pagination';
import NoData from 'components/common/noData';

import { setUserSelectedLocal } from 'store/slices/common';
import { localList } from 'app/constants';

const PAGE_SIZE = 10;

export default function BoardList({ tableHeader, localFilter, tableBody, loading, title }) {
  // hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // 지역 선택 method
  const setRegion = region => {
    searchParams.set('page', 1);
    searchParams.set('searchType', '');
    searchParams.set('search', '');
    setSearchParams(searchParams);
    dispatch(setUserSelectedLocal(localList.find(local => local.code === region).name));
  };

  // 페이지 이동 method
  const handlePageChange = pageNo => {
    const totalPages = tableBody?.pagination.totalPage;
    if (pageNo >= 1 && pageNo <= totalPages) {
      searchParams.set('page', pageNo);
      setSearchParams(searchParams);
    }
  };

  // 검색 method
  const getSearchText = ({ type, text }) => {
    searchParams.set('page', 1);
    searchParams.set('searchType', type);
    searchParams.set('search', text);
    setSearchParams(searchParams);
  };
  return (
    <>
      {localFilter && <LocalSort setRegion={setRegion} />}
      <Box className="board-list">
        <Flex justify="between" align="center" mt="var(--space-12)" height="48px">
          <Text className="board-counter">
            Total :<Strong>&nbsp;{tableBody?.pagination.totalCount?.toLocaleString()}</Strong>건
          </Text>
          <Flex justify="end" align="center" gap="2" className="board-search-box">
            <BoardSearchBox getSearchText={getSearchText} />
          </Flex>
        </Flex>
        {loading ? null : tableBody?.pagination?.totalCount > 0 ? (
          <>
            <Box mt="4">
              <ListType totalCount={tableBody?.pagination.totalCount} tableHeader={tableHeader} tableData={tableBody.list} title={title} />
            </Box>
            <Flex justify="center" mt="var(--space-12)">
              <Pagination totalPosts={tableBody?.pagination.totalCount} postsPerPage={PAGE_SIZE} handlePageChange={handlePageChange} />
            </Flex>
          </>
        ) : (
          <NoData />
        )}
      </Box>
    </>
  );
}
