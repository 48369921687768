import React from 'react';
import { Container, Section } from '@radix-ui/themes';

import Visual from 'components/common/visual';
import Breadcrumbs from 'components/common/breadcrumbs';
import PageTitle from 'components/layout/pageTitle';
import BoardDetail from 'components/board/boardDetail';

// 공지사항 > 공지사항 상세 페이지
export default function NoticeDetail() {
  const pageName = '공지사항';
  const boardType = 'notice';

  return (
    <>
      <Visual category={boardType} title={pageName} />
      <Breadcrumbs />
      <Section p="0" mb={'var(--space-35)'} className={boardType}>
        <Container size="4">
          <PageTitle icon={boardType} title={pageName} />
          <BoardDetail boardType={boardType} />
        </Container>
      </Section>
    </>
  );
}
