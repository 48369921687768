import React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';

import './style.scss';

export default function Pagination({ totalPosts, postsPerPage, handlePageChange }) {
  // hooks
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;

  // state
  const [pageNumbers, setPageNumbers] = useState([]);
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const pageLimit = 5;
  const startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
  const endPage = Math.min(totalPages, startPage + pageLimit - 1);

  useEffect(() => {
    // 페이지 번호 배열 계산
    const numbers = [];
    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i);
    }

    setPageNumbers(numbers);
  }, [endPage, startPage]);

  return (
    <Box className="pagination">
      <Flex>
        <Flex gap="var(--space-1)">
          <IconButton
            title="처음으로"
            variant="outline"
            radius="full"
            color="gray"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <DoubleArrowLeftIcon width={18} height={18} />
          </IconButton>
          <IconButton
            title="이전"
            variant="outline"
            radius="full"
            color="gray"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon width={18} height={18} />
          </IconButton>
        </Flex>
        <Flex className="pages" mx="6">
          {pageNumbers.map(pageNum => (
            <IconButton
              key={pageNum}
              size="1"
              className={`rt-variant-text ${currentPage === pageNum ? 'currentPage' : ''}`}
              onClick={() => handlePageChange(pageNum)}
            >
              {pageNum}
            </IconButton>
          ))}
        </Flex>
        <Flex gap="var(--space-1)">
          <IconButton
            title="다음"
            variant="outline"
            radius="full"
            color="gray"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon width={18} height={18} />
          </IconButton>
          <IconButton
            title="마지막으로"
            variant="outline"
            radius="full"
            color="gray"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <DoubleArrowRightIcon width={18} height={18} />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  );
}
