import React, { useRef } from 'react';
import { Box, Button } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import './style.scss';

const IMAGES = {
  imgPartner1: {
    src: require('assets/images/common/img-partner-banner.svg').default,
    alt: '과학기술정보통신부',
    url: 'https://www.msit.go.kr/',
    target: '_blank'
  },
  imgPartner2: {
    src: require('assets/images/common/img-partner-banner-1.svg').default,
    alt: 'nipa정보통신산업진흥원',
    url: 'https://www.nipa.kr/',
    target: '_blank'
  },
  imgPartner3: {
    src: require('assets/images/common/img-partner-banner-2.svg').default,
    alt: 'SW미래채움',
    url: 'https://sweduhub.or.kr/',
    target: '_blank'
  },
  imgPartner4: {
    src: 'https://www.ice.go.kr/images/template/TA/layout/logo.png',
    alt: '인천광역시교육청',
    url: 'https://www.ice.go.kr/',
    target: '_blank'
  },
  imgPartner5: {
    src: 'https://www.gg.go.kr/site/gg/common/img/img-header-logo_230111.png',
    alt: '경기시',
    url: 'https://www.gg.go.kr/',
    target: '_blank'
  },
  imgPartner6: {
    src: require('assets/images/common/img-partner-banner-4.svg').default,
    alt: '포항시',
    url: 'https://www.pohang.go.kr/',
    target: '_blank'
  },
  imgPartner7: {
    src: require('assets/images/common/img-partner-banner-3.svg').default,
    alt: '경상북도',
    url: 'https://www.gb.go.kr/',
    target: '_blank'
  },
  imgPartner8: {
    src: 'https://mybiz.gsipa.or.kr/data/designImages/BANNER_1632877872logo_new1.png',
    alt: '강릉과학산업진흥원',
    url: 'https://mybiz.gsipa.or.kr/',
    target: '_blank'
  },
  imgPartner9: {
    src: 'https://www.gntp.or.kr/assets/images/head/ci.png',
    alt: '경남테크노파크',
    url: 'https://www.gntp.or.kr/',
    target: '_blank'
  }
};

export const PartnerSlider = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <Box className="partner-slider">
        <Swiper
          autoplay={{
            delay: 1500,
            disableOnInteraction: true
          }}
          loop={true}
          slidesPerView={'auto'}
          // slidesPerView={5}
          spaceBetween={8}
          modules={[Autoplay, Navigation]}
          navigation={{
            prevEl: prevRef.current, //스와이퍼 외부에 컨트롤러 설정하기
            nextEl: nextRef.current
          }}
          onBeforeInit={swiper => {
            // 초기화 전에 네비게이션 버튼을 swiper에 할당합니다.
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {Object.keys(IMAGES).map((key, index) => (
            <SwiperSlide className="slider-item" key={index}>
              <a href={IMAGES[key].url} target={IMAGES[key].target}>
                <img src={IMAGES[key].src} alt={IMAGES[key].alt} />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <Button className="slider-prev" ref={prevRef}>
          Prev
        </Button>
        <Button className="slider-next" ref={nextRef}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default PartnerSlider;
