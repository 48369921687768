import React, { useEffect, useRef } from 'react';

export default function Map({ mapPosition }) {
  const defaultLatitude = 0;
  const defaultLongitude = 0;
  const mapRef = useRef(null);

  useEffect(() => {
    window.kakao.maps.load(() => {
      const container = mapRef.current;
      const mapLatitude = mapPosition?.latitude || defaultLatitude;
      const mapLongitude = mapPosition?.longitude || defaultLongitude;
      const options = {
        center: new window.kakao.maps.LatLng(mapLatitude, mapLongitude),
        level: 3
      };
      // 지도 생성
      const map = new window.kakao.maps.Map(container, options);
      // 마커 위치 설정
      const markerPosition = new window.kakao.maps.LatLng(mapLatitude, mapLongitude);
      const marker = new window.kakao.maps.Marker({
        position: markerPosition
      });
      // 지도에 마커 표시
      marker.setMap(map);
    });
  }, [mapPosition, mapRef]);

  return <div ref={mapRef} id="map" style={{ width: '100%', height: '400px' }} />;
}
