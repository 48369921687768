import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { useSelector } from 'react-redux';

import NoData from 'components/common/noData';
import Map from 'components/localCenter/Map';

import { selectUserSelectedLocal } from 'store/slices/common';

const MapDirections = ({ localData }) => {
  // hooks
  const userSelectedLocal = useSelector(selectUserSelectedLocal);

  // state
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapRendered, setIsMapRendered] = useState(false);
  const [mapPositions, setMapPositions] = useState([]);

  // kakao map 로드 method
  const loadKakaoMapScript = () => {
    return new Promise((resolve, reject) => {
      if (window.kakao && window.kakao.maps) {
        resolve(window.kakao);
      }
      const mapScript = document.createElement('script');
      mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAOMAP_APPKEY}&libraries=services&autoload=false`;
      mapScript.onload = () => {
        window.kakao.maps.load(() => {
          resolve(window.kakao);
          setIsMapLoaded(true);
        });
      };
      mapScript.onerror = error => reject(error);
      document.head.appendChild(mapScript);
    });
  };

  // 카카오 지도 서비스 객체를 이용하여 주소 -> 좌표 변환
  const waitForMapLoad = useCallback(async addressArray => {
    setIsMapRendered(false);

    try {
      const geocoder = new window.kakao.maps.services.Geocoder();

      // 각 주소를 Promise로 변환
      const promises = addressArray.map(address => {
        return new Promise((resolve, reject) => {
          geocoder.addressSearch(address, (result, status) => {
            if (status === window.kakao.maps.services.Status.OK) {
              resolve({
                latitude: result[0].y,
                longitude: result[0].x
              });
            } else {
              reject(new Error(`Failed to fetch coordinates for address: ${address}`));
            }
          });
        });
      });

      // 모든 주소의 좌표를 변환 (순서 보장)
      const positions = await Promise.all(promises);
      setMapPositions(positions); // 변환된 좌표 설정
    } catch (error) {
      console.error(error);
    } finally {
      setIsMapRendered(true); // 렌더링 완료 표시
    }
  }, []);

  // mount 시 카카오맵 스크립트 로드
  useEffect(() => {
    loadKakaoMapScript();
  }, []);

  // 스크립트 로드 완료 시 지도 그리기
  useEffect(() => {
    if (isMapLoaded && localData) {
      waitForMapLoad(localData.map(data => data?.addressMap));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapLoaded, userSelectedLocal, localData]);

  if (localData?.length === 0) return <NoData />;
  return (
    <>
      {localData?.map((item, index) => (
        <Box mt="48px" className="map-wrap no-border" key={index}>
          <Flex align="center">
            <Heading as="h5" className="title3">
              {item.centerName}
            </Heading>
            <Text as="p" className="body3 extra">
              {item.caution}
            </Text>
          </Flex>
          <Box mt="40px" mb="60px" className="map">
            {/* 지도 표시 */}
            {isMapRendered && <Map mapPosition={mapPositions[index]} />}
          </Box>
          <Box mb="60px">
            <Text as="p" className="title4">
              주소
            </Text>
            <Text as="p" className="sub-title regular">
              {item.addressText}
            </Text>
          </Box>
          {item?.guidance?.map((item, index) => (
            <Box mb="60px" key={index}>
              <Text as="p" className={`title4 icon ${item.category}`}>
                {item.title}
              </Text>
              {item?.ways?.map((way, index) => (
                <Text as="p" className="sub-title regular" key={index}>
                  <b>{way.title}</b> : {way.content}
                </Text>
              ))}
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

export default MapDirections;
