import React from 'react';
import '@radix-ui/themes/styles.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Theme } from '@radix-ui/themes';

import Header from 'components/layout/header';
import Footer from 'components/layout/footer';

import ScrollToTop from './hooks/useScrollTop';
import RouteList from 'app/router';
import 'assets/scss/tokens/df-user/token.scss';

function App() {
  return (
    <div className="App">
      <Theme accentColor={'blue'}>
        <Router>
          <Routes>
            {RouteList.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <>
                    <ScrollToTop>
                      {!route.noHeaderFooter && <Header />}
                      <main id="contents">{route.element}</main>
                      {!route.noHeaderFooter && <Footer />}
                    </ScrollToTop>
                  </>
                }
              />
            ))}
          </Routes>
        </Router>
      </Theme>
    </div>
  );
}

export default App;
