import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Container, Flex, Heading, Section, Text } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LocalSort from 'components/common/localSort';
import PageTitle from 'components/layout/pageTitle';
import LocalMap from 'components/common/localMap';
import { MainSlider, PartnerSlider } from 'components/common/slider';
import NoData from 'components/common/noData';
import Popup from 'components/popup';

import { localList } from 'app/constants';
import { selectUserSelectedLocalCode, setUserSelectedLocal } from 'store/slices/common';
import { instance } from 'api/axios.instance';
import './style.scss';

// 메인 페이지
export default function Main() {
  // hooks
  const dispatch = useDispatch();
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);

  // state
  const [sliderList, setSliderList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('전체');
  const [selectedMonth] = useState(new Date().getMonth() + 1);
  const baseURL = process.env.REACT_APP_API_BASE;
  const [showPopup, setShowPopup] = useState(false);

  // 교육 & 홍보 게시판 조회 api
  const fetchSlider = useCallback(
    async (regionCode, controller) => {
      try {
        const response = await instance(`${baseURL}/main/post?region=${regionCode}&month=${selectedMonth}`, {
          method: 'GET',
          signal: controller.signal
        });

        if (response.data.status === 200) {
          setSliderList(response.data.data.posts);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [baseURL, selectedMonth]
  );

  // 공지사항 목록 조회 api
  const fetchNotice = useCallback(async () => {
    const response = await instance(`${baseURL}/post/list`, {
      method: 'POST',
      data: {
        boardType: 'notice',
        region: 'ALL',
        searchType: '',
        search: '',
        pageNo: 1,
        pageSize: 3,
        inOrder: 'DESC'
      }
    });

    if (response.data.status === 200) {
      setNoticeList(response.data.data.posts);
    }
  }, [baseURL]);

  // 홍보자료 목록 조회 api
  const fetchEvent = useCallback(async () => {
    const response = await instance(`${baseURL}/post/list`, {
      method: 'POST',
      data: {
        boardType: 'promotion',
        region: 'ALL',
        searchType: '',
        search: '',
        pageNo: 1,
        pageSize: 3,
        inOrder: 'DESC'
      }
    });

    if (response.data.status === 200) {
      setEventList(response.data.data.posts);
    }
  }, [baseURL]);

  // 메인 슬라이드 지역 선택 method
  const setRegion = async region => {
    dispatch(setUserSelectedLocal(localList.find(local => local.code === region).name));
  };

  // 지도 지역 선택 method
  const handleClick = (region, e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      setSelectedRegion(region);
    }
  };

  // 메인 팝업 닫기 method
  const handleClose = val => {
    if (val) {
      const expires = new Date().setHours(new Date().getHours() + 24);
      localStorage.setItem('visited', expires);
    }
    setShowPopup(false);
  };

  // mount 시 메인 팝업 노출
  useEffect(() => {
    const currentTime = new Date().getTime();
    const VISITED = localStorage.getItem('visited');
    const handleShowPopup = () => {
      if (!VISITED || currentTime > VISITED) {
        localStorage.removeItem('visited');
        setShowPopup(true);
      }
    };
    window.setTimeout(handleShowPopup, 500);
  }, []);

  // mount 시 실행
  useEffect(() => {
    fetchEvent();
    fetchNotice();
  }, [fetchEvent, fetchNotice]);

  // mount 시 실행
  useEffect(() => {
    if (userSelectedLocalCode) {
      const controller = new AbortController();
      fetchSlider(userSelectedLocalCode, controller);

      return () => controller.abort();
    }
  }, [userSelectedLocalCode, fetchSlider]);

  return (
    <>
      {/* 홍보용 팝업 - 일회성 */}
      {showPopup && <Popup onClose={handleClose} />}
      <section className="visual main">
        <Container size="4">
          <Heading as="h2" className="title2">
            소프트웨어 인재양성을 이끄는
          </Heading>
          <div className="image-wrap">
            <img src={require('assets/images/common/logo.svg').default} alt={'SW미래채움'} />
          </div>
        </Container>
      </section>
      <Section p="0" className="main">
        <Container size="4">
          <Flex align="center">
            <LocalSort setRegion={setRegion} />
            {/* <Box>
              <Flex align="center" className="month-sorting">
                <Button
                  radius="full"
                  className="prev"
                  onClick={() =>
                    setSelectedMonth((prev) => (prev > 1 ? prev - 1 : 12))
                  }
                >
                  이전
                </Button>
                <Strong className="title4">{selectedMonth}월</Strong>
                <Button
                  radius="full"
                  className="next"
                  onClick={() =>
                    setSelectedMonth((prev) => (prev < 12 ? prev + 1 : 1))
                  }
                >
                  다음
                </Button>
              </Flex>
            </Box> */}
          </Flex>
        </Container>
        <Box className="slider-wrap">
          {/* 메인 슬라이더 */}
          {sliderList?.length > 0 ? <MainSlider sliderList={sliderList} /> : <NoData />}
        </Box>
        <Container size="4">
          <Box mt="60px" mb="100px">
            <Link to="/notice/detail/6819" title="SW미래채움 홈페이지 개편 안내로 이동" className="slim-banner">
              <img src={require('assets/images/common/main-notice-banner.png')} alt="SW미래채움 홈페이지 개편 안내" />
            </Link>
          </Box>
          <Box mb="100px">
            <PageTitle icon="star" title="홍보자료" />
            <Flex justify="between">
              {/* .chips.local에 지역명을 class로 입력시 해당 지역의 배지에 배경 색이 들어갑니다. 
                강원: gangwon, 경기: gyeonggi, 경남: gyeongnam, 경북: gyeongbuk, 광주: gwangju,
                대구: daegu, 울산: ulsan, 인천: incheon, 전남: jeonnam, 전북: jeonbuk,
                제주: jeju, 충남: chungnam , 충북: chungbuk
              */}
              {eventList?.map((item, index) => (
                <Box key={index}>
                  <Link to={`/promotion/detail/${item.postNo}`} title={`${item.title}로 이동`}>
                    <Card className="card image">
                      <Box className="header image-wrap">{item.thumbnail && <img src={item.thumbnail} alt={item.title} />}</Box>
                      <Box className="body">
                        <Text className={`chips local ${localList.find(data => data.name === item.region)?.en}`}>{item.region}</Text>
                        <Text as="p" className="sub-title title-2line">
                          {item.title}
                        </Text>
                        <Flex justify="between" className="utility">
                          <Text className="body2">{new Date(item.createDate).toISOString().split('T')[0]}</Text>
                          <Text className="body2 view">{item.viewCount}</Text>
                        </Flex>
                      </Box>
                    </Card>
                  </Link>
                </Box>
              ))}
            </Flex>
          </Box>
          <Box mb="100px">
            <Flex justify="between">
              <Box className="text-wrap">
                <PageTitle icon="local" title="지역교육을 한눈에!" />
                <Box className="card layer">
                  <Box className="body">
                    <Box className="image-wrap">
                      <img src={require('assets/images/common/logo.svg').default} alt={'SW미래채움'} />
                    </Box>
                    <Box className="text-wrap">
                      <Text as="p" className="title5">
                        SW 미래채움 {selectedRegion}센터
                      </Text>
                      <Text as="p" className="body2">
                        {selectedRegion}센터는 SW교육을 통해 지역의 SW 인재를 양성하고 있습니다.
                      </Text>
                    </Box>
                  </Box>
                  {selectedRegion !== '전체' && (
                    <Box className="footer">
                      <Link
                        onClick={() => dispatch(setUserSelectedLocal(selectedRegion))}
                        to={`/localOrganization`}
                        title="조직도로 이동"
                        className="sub-title"
                      >
                        조직도
                      </Link>
                      <Link
                        onClick={() => dispatch(setUserSelectedLocal(selectedRegion))}
                        to={`/localMap`}
                        title="찾아오시는 길로 이동"
                        className="sub-title"
                      >
                        찾아오시는 길
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className="map-wrap">
                <LocalMap handleClick={handleClick} selectedRegion={selectedRegion} />
              </Box>
            </Flex>
          </Box>
          <Box mb="140px">
            <Flex align="end">
              <Box className="notice-wrap">
                <PageTitle icon="bubble" title="공지사항" />
                <Box className="notice">
                  {noticeList?.map((item, index) => (
                    <Link key={index} to={`/notice/detail/${item.postNo}`} title={item.title} className="body1 bold">
                      <Text>
                        [{item.region}] {item.title}
                      </Text>
                      <Text className="regular date">{new Date(item.createDate).toISOString().split('T')[0]}</Text>
                    </Link>
                  ))}
                </Box>
              </Box>
              <Box className="buttons">
                <Link to="/intro" title="사업소개로 이동" className="title5">
                  사업 소개
                  <span className="image-wrap">
                    <img src={require('assets/images/common/img-book.svg').default} alt="사업 소개" />
                  </span>
                </Link>
                <Link to="/ci" title="CI 소개로 이동" className="title5">
                  CI 소개
                  <span className="image-wrap">
                    <img src={require('assets/images/common/logo-sw.svg').default} alt="CI 소개" />
                  </span>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Container>
      </Section>
      <Section p="0" className="partners">
        <Container size="4">
          <Flex justify="between" align="center">
            <Text as="p" className="sub-title">
              함께하는 기관
            </Text>
            <PartnerSlider />
          </Flex>
        </Container>
      </Section>
    </>
  );
}
