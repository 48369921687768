import React, { useState } from 'react';
import { Flex, Text, IconButton, Box, Checkbox } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

import './style.scss';

export default function Popup({ onClose }) {
  const [isToday, setIsToday] = useState(false);

  const handleCheckbox = () => {
    setIsToday(prev => !prev);
  };

  const handleClose = () => {
    onClose(isToday);
  };

  return (
    <Box className="main-popup">
      <IconButton variant="ghost" className="rt-variant-text close-btn" onClick={handleClose} title="닫기">
        <Cross1Icon color="white" width={36} height={36} alt="닫기" />
      </IconButton>
      <Link to="/notice" title="공지사항으로 이동">
        <img src={require('assets/images/temp/popup-notice-img.png')} alt="SW미래채움 홈페이지 개편 안내" />
      </Link>
      <Box className="popup-footer">
        <label>
          <Flex align="center" gap="var(--space-3)">
            <Checkbox id="today" checked={isToday} onCheckedChange={handleCheckbox} size="3" />
            <label htmlFor="today">
              <Text className="body2 font-basic">오늘 하루 보지 않기</Text>
            </label>
          </Flex>
        </label>
      </Box>
    </Box>
  );
}
