import { createSlice } from '@reduxjs/toolkit';
import { localList } from 'app/constants';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    userSelectedLocal: '전체',
    userSelectedLocalCode: 'ALL'
  },

  reducers: {
    setUserSelectedLocal: (state, action) => {
      state.userSelectedLocal = action.payload;
      const index = localList.findIndex(local => local.name === action.payload);
      state.userSelectedLocalCode = localList[index].code || 'ALL';
    }
  }
});
export const { setUserSelectedLocal } = commonSlice.actions;
export const selectUserSelectedLocal = state => state.common.userSelectedLocal;
export const selectUserSelectedLocalCode = state => state.common.userSelectedLocalCode;

export default commonSlice;
