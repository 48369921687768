import React from 'react';
import './style.scss';
import { Container } from '@radix-ui/themes';

export default function Visual({ category, title }) {
  return (
    <section className={`visual ${category}`}>
      <Container size="4">
        <h2 className="title2">{title}</h2>
      </Container>
    </section>
  );
}
