import React, { useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { Link, useLocation } from 'react-router-dom';
import SkipNavigation from 'components/common/skipnav';
import './style.scss';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleMouseOver = () => {
    setIsMenuOpen(true);
  };

  const handleMouseOut = () => {
    setIsMenuOpen(false);
  };

  const isLinkActive = link => {
    const cleanedPath = currentPath.split('?')[0];

    if (link === '/localCenter') {
      return ['/localCenter', '/localOrganization', '/localMap'].includes(cleanedPath);
    }

    const regex = new RegExp(`^${link}(/|$)`);
    return regex.test(cleanedPath);
  };

  const isMenuActive = (depth1Url, depth2Urls) => {
    const cleanedPath = currentPath.split('?')[0];

    // depth1 URL이 현재 경로와 정확히 일치하거나 하위 경로인지 확인
    const isDepth1Active = new RegExp(`^${depth1Url}(/|$)`).test(cleanedPath);

    // depth2 URL 중 현재 경로와 정확히 일치하거나 하위 경로인 경우
    const isAnyDepth2Active = depth2Urls?.some(url => new RegExp(`^${url}(/|$)`).test(cleanedPath));

    return isDepth1Active || isAnyDepth2Active;
  };

  return (
    <>
      <SkipNavigation />
      <header>
        <Container size="4">
          <Flex align="center" className="container">
            <h1 className="logo">
              <Link to="/" title="메인으로 이동">
                SW미래채움
              </Link>
            </h1>
            <nav id="menu" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onKeyUp={handleMouseOver}>
              <ul className="depth1">
                <li>
                  <Link
                    to={'/intro'}
                    className={`sub-title ${isMenuActive('/intro', ['/intro', '/promotionSystem', '/localCenter', '/ci']) ? 'active' : ''}`}
                    title={`소개로 이동`}
                  >
                    {'소개'}
                  </Link>

                  {isMenuOpen && (
                    <div className="depth2 sub-menu1">
                      <ul>
                        <li>
                          <Link
                            to={'/intro'}
                            className={`sub-menu-item ${isLinkActive('/intro') ? 'active' : ''}`}
                            title={'사업소개로 이동'}
                          >
                            {'사업소개'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/promotionSystem'}
                            className={`sub-menu-item ${isLinkActive('/promotionSystem') ? 'active' : ''}`}
                            title={'사업 추진체계로 이동'}
                          >
                            {'사업 추진체계'}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={'/localCenter'}
                            className={`sub-menu-item ${isLinkActive('/localCenter') ? 'active' : ''}`}
                            title={'지역센터소개로 이동'}
                          >
                            {'지역센터소개'}
                          </Link>
                        </li>
                        <li>
                          <Link to={'/ci'} className={`sub-menu-item ${isLinkActive('/ci') ? 'active' : ''}`} title={'사업 CI로 이동'}>
                            {'사업 CI'}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={'/education'}
                    className={`sub-title ${isMenuActive('/education', ['/education']) ? 'active' : ''}`}
                    title={`교육안내로 이동`}
                  >
                    {'교육안내'}
                  </Link>
                  {isMenuOpen && (
                    <div className="depth2 sub-menu2">
                      <ul>
                        <li>
                          <Link
                            to={'/education'}
                            className={`sub-menu-item ${isLinkActive('/education') ? 'active' : ''}`}
                            title={'교육정보로 이동'}
                          >
                            {'교육정보'}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={'/event'}
                    className={`sub-title ${isMenuActive('/event', ['/event']) ? 'active' : ''}`}
                    title={`행사안내로 이동`}
                  >
                    {'행사안내'}
                  </Link>
                  {isMenuOpen && (
                    <div className="depth2 sub-menu3">
                      <ul>
                        <li>
                          <Link
                            to={'/event'}
                            className={`sub-menu-item ${isLinkActive('/event') ? 'active' : ''}`}
                            title={'행사정보로 이동'}
                          >
                            {'행사정보'}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={'/notice'}
                    className={`sub-title ${isMenuActive('/notice', ['/notice']) ? 'active' : ''}`}
                    title={`공지사항로 이동`}
                  >
                    {'공지사항'}
                  </Link>
                  {isMenuOpen && (
                    <div className="depth2 sub-menu4">
                      <ul>
                        <li>
                          <Link
                            to={'/notice'}
                            className={`sub-menu-item ${isLinkActive('/notice') ? 'active' : ''}`}
                            title={'공지사항로 이동'}
                          >
                            {'공지사항'}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to={'/promotion'}
                    className={`sub-title ${isMenuActive('/promotion', ['/promotion']) ? 'active' : ''}`}
                    title={`홍보자료로 이동`}
                  >
                    {'홍보자료'}
                  </Link>
                  {isMenuOpen && (
                    <div className="depth2 sub-menu5">
                      <ul>
                        <li>
                          <Link
                            to={'/promotion'}
                            className={`sub-menu-item ${isLinkActive('/promotion') ? 'active' : ''}`}
                            title={'홍보자료로 이동'}
                          >
                            {'홍보자료'}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </Flex>
        </Container>
        <div
          className={`menu-wrap ${isMenuOpen ? 'open' : ''}`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onKeyUp={handleMouseOver}
        ></div>
      </header>
    </>
  );
}
