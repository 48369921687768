// 메인 페이지
import Main from 'pages/main';

// 소개 페이지
import Intro from 'pages/intro';
import PromotionSystem from 'pages/promotionSystem';
import LocalCenter from 'pages/localCenter';
import CI from 'pages/ci';

// 교육안내 페이지
import Education from 'pages/education';
import EducationDetail from 'pages/education/Detail';

// 행사안내 페이지
import Event from 'pages/event';
import EventDetail from 'pages/event/Detail';

// 공지사항 페이지
import Notice from 'pages/notice';
import NoticeDetail from 'pages/notice/Detail';

// 홍보자료 페이지
import Promotion from 'pages/promotion';
import PromotionDetail from 'pages/promotion/Detail';

// 개인정보처리방침 페이지
import Privacy from 'pages/privacy';

// 에러 페이지
import Error404 from 'components/common/error/Error404';

const RouteList = [
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/intro',
    element: <Intro />
  },
  {
    path: '/ci',
    element: <CI />
  },
  {
    path: '/localCenter',
    element: <LocalCenter />
  },
  {
    path: '/localOrganization',
    element: <LocalCenter />
  },
  {
    path: '/localMap',
    element: <LocalCenter />
  },
  {
    path: '/promotionSystem',
    element: <PromotionSystem />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/education',
    element: <Education />
  },
  {
    path: '/education/detail/:id',
    element: <EducationDetail />
  },
  {
    path: '/event',
    element: <Event />
  },
  {
    path: '/event/detail/:id',
    element: <EventDetail />
  },
  {
    path: '/notice',
    element: <Notice />
  },
  {
    path: '/notice/detail/:id',
    element: <NoticeDetail />
  },
  {
    path: '/promotion',
    element: <Promotion />
  },
  {
    path: '/promotion/detail/:id',
    element: <PromotionDetail />
  },
  {
    path: '*',
    element: <Error404 />,
    noHeaderFooter: true
  }
];

export default RouteList;
