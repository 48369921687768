import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@radix-ui/themes';

import './style.scss';

export default function Error404() {
  const navigate = useNavigate();

  return (
    <Flex direction="column" align="center" justify="center" gap="var(--space-20)" px="var(--space-15)" className="error-page-wrap">
      <Flex direction="column" align="center" justify="center" gap="var(--space-7)">
        <img src={require('assets/images/common/error-404.svg').default} alt="404 에러페이지" />
        <Text as="p" align="center" className="title3">
          페이지를 찾을 수 없습니다.
        </Text>
        <Text as="p" align="center" color="gray" className="body1">
          방문하시려는 페이지의 주소가 잘못 입력되었거나, 변경 혹은 삭제되어 찾을 수 없습니다.
          <br />
          서비스 이용에 불편을 드려 죄송합니다.
        </Text>
      </Flex>
      <Button radius="full" onClick={() => navigate('/')} className="home-btn">
        홈으로 이동
      </Button>
    </Flex>
  );
}
