import React from 'react';
import { Box, Flex, Text } from '@radix-ui/themes';
import { useLocation } from 'react-router-dom';

export default function NoData() {
  const { pathname } = useLocation();
  const path = pathname.replace('/', '');

  return (
    <>
      {path === 'localCenter' ? (
        <Flex direction="column" align="center" justify="center" mt="var(--space-12)">
          <img src={require('assets/images/common/no-select-local.png')} alt="데이터 없음" />
          <Text as="p" mt="var(--space-10)" className="title5">
            지역을 선택해 주세요.
          </Text>
          <Text as="p" mt="var(--space-4)" className="body2 font-basic">
            각 센터별 교육정보를 확인할 수 있습니다.
          </Text>
        </Flex>
      ) : (
        <Box mt="var(--space-2)">
          <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data" py="196px">
            <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터 없음" />
            <Text as="p" color="gray" className="body1">
              데이터가 없습니다.
            </Text>
          </Flex>
        </Box>
      )}
    </>
  );
}
